import { Analytics, Context, User } from '@segment/analytics-next';

import { sha256 } from './cryptoUtils';

export const getCookie = (name: string): string => {
  const cookie = document.cookie.split('; ').find((row) => row.startsWith(`${name}=`));
  return cookie ? cookie.split('=')[1] : '';
};

/**
 *
 * @returns Google Universal Analytics ID
 */
export const getGoogleAnalyticsClientId = (): string => getCookie('_ga');

export const generateOrderId = (): string =>
  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

export const trackConversionLead = async (data: Record<string, unknown>) => {
  const orderId = generateOrderId();
  analytics.track('Qualified Lead', {
    email: data.email,

    phone: data.phoneNumber,
    firstName: data.firstName,
    lastName: data.lastName,

    // This is needed for gtag to work properly.
    // The same property is used for Google Enhanced Conversions via Segment.
    transaction_id: orderId,

    address: {
      street: data.street,
      city: data.city,
      state: data.state,
      postalCode: data.zipCode,
      country: data.countryCode,
    },

    integrations: {
      'Google Universal Analytics': {
        clientId: getGoogleAnalyticsClientId(),
      },
    },
  });
};

export enum ConversionType {
  CollectedName = 'CollectedName',
  CollectedEmail = 'CollectedEmail',
  CollectedPhone = 'CollectedPhone',

  // Applied
  CardApplied = 'CardApplied',
  FinancialCheckupApplied = 'FinancialCheckupApplied',

  // Cross sell/referral opportunities
  DebtConsolidationOfferAvailable = 'DebtConsolidationOfferAvailable',
  StudentLoanOfferAvailable = 'StudentLoanOfferAvailable',
  SocialShareInitiated = 'SocialShareInitiated',

  // Cross sell conversions
  ConfirmedLoan = 'ConfirmedLoan',
  BookedStudentLoanMeeting = 'BookedStudentLoanMeeting',
  LoanManualVerify = 'LoanManualVerify',
  LoanFundsSent = 'LoanFundsSent',

  // Deprecated
  LoanApplied = 'LoanApplied',
  LoanAppliedAndQualified = 'LoanAppliedAndQualified',
}

/**
 * These are estimated dollar amounts that we value each of these events occurring.
 * This helps ad networks optimize overall conversion value.
 */

export const CONVERSION_VALUES: Readonly<Record<ConversionType, number>> = {
  [ConversionType.CollectedName]: 0,
  [ConversionType.CollectedEmail]: 0,
  [ConversionType.CollectedPhone]: 0,

  // Applied
  [ConversionType.CardApplied]: 5,
  [ConversionType.FinancialCheckupApplied]: 5,

  // Cross sell/referral opportunities
  [ConversionType.DebtConsolidationOfferAvailable]: 10,
  [ConversionType.StudentLoanOfferAvailable]: 10,
  [ConversionType.SocialShareInitiated]: 10,

  // Cross sell conversions
  [ConversionType.ConfirmedLoan]: 75,
  [ConversionType.BookedStudentLoanMeeting]: 75,
  [ConversionType.LoanManualVerify]: 100,
  [ConversionType.LoanFundsSent]: 250,

  // Deprecated
  [ConversionType.LoanApplied]: 5,
  [ConversionType.LoanAppliedAndQualified]: 25,
} as const;

enum EventName {
  Conversion = 'Conversion',
  ConversionCollectedEmail = 'Conversion - Collected Email',
  ConversionCardApplied = 'Conversion - Card Applied',
  ConversionLoanApplied = 'Conversion - Loan Applied',
}

const CONVERSION_TYPE_TO_EVENT_NAME: Partial<Readonly<Record<ConversionType, EventName>>> = {
  [ConversionType.CollectedEmail]: EventName.ConversionCollectedEmail,
  [ConversionType.CardApplied]: EventName.ConversionCardApplied,
  [ConversionType.LoanApplied]: EventName.ConversionLoanApplied,
} as const;

export const trackConversion = async (type: ConversionType) => {
  const value = CONVERSION_VALUES[type];
  if (!value) {
    return;
  }

  analytics.then(async (instance: [Analytics, Context]) => {
    const user = instance[0].user();

    const promises = [trackConversionEvent(EventName.Conversion, type, user, { value, currency: 'USD' })];

    // Track individual events for important conversions.
    const eventName = CONVERSION_TYPE_TO_EVENT_NAME[type];
    if (eventName) {
      promises.push(trackConversionEvent(eventName, type, user));
    }

    await Promise.all(promises);
  });
};

/**
 * This is mapped to Facebook's event ID, which prevents
 * duplicate events for the same user and the same conversion type.
 */
const getEventId = async (eventName: EventName, user: User, type: ConversionType) =>
  sha256(eventName, user.id() || user.anonymousId() || '', type);

const trackConversionEvent = async (
  eventName: EventName,
  type: ConversionType,
  user: User,
  params?: Record<string, string | number>,
) => {
  const traits = user.traits()!;
  const eventOptions = {
    traits: {
      email: traits.email,
      phoneNumber: traits.phoneNumber,
      birthday: traits.birthday,
      lastName: traits.lastName,
      firstName: traits.firstName,
      fbp: traits.fbp,
      fbc: traits.fbc,
      clientCountry: traits.clientCountry,
      clientRegion: traits.clientRegion,
      clientCity: traits.clientCity,
    },
  };
  analytics.track(eventName, { ...params, type, eventId: await getEventId(eventName, user, type) }, eventOptions);
};
