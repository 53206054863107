import React from 'react';

import { RoutePath } from 'enums/Routes';
import RewardProgress from 'components/ReferralProgram/RewardProgressBar/RewardProgressBar';
import { Debt, DebtSummary, HardOfferData, LoanType, StudentLoanAssistanceAppointment } from 'handlers/applicationData';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';

import { ReactComponent as SignLoanAgreementIcon } from 'images/action-items/sign-agreement.svg';
import { ReactComponent as SetupRepaymentIcon } from 'images/action-items/setup-repayment.svg';
import { ReactComponent as VerifyEmploymentIcon } from 'images/action-items/suitcase.svg';
import { ReactComponent as StudentLoanIcon } from 'images/action-items/student-loan.svg';
import { ReactComponent as PlanneryCardIcon } from 'images/action-items/credit-card.svg';
import { ReactComponent as ReferralProgramIcon } from 'images/action-items/spread-word.svg';
import { ReactComponent as DebtConsolidationIcon } from 'images/action-items/down-trend.svg';
import { useSelector } from 'react-redux';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import { getApplicationData } from 'selectors/getApplicationData';
import { getCardData } from 'selectors/getCardData';

export interface ActionItem {
  id: string;
  name: string;
  description: JSX.Element;
  icon: React.FunctionComponent<{ className?: string }>;
  route: RoutePath;
  visible: boolean;
}

interface ItemsData {
  savings?: number;
  monthsSaved?: number;
  studentLoanEligible: boolean;
  studentLoansBalance?: number;
  studentLoanAssistanceAppointment?: StudentLoanAssistanceAppointment;
  waitlistPosition?: number;
  waitlistLength?: number;
  cardCashbackPercentage: number;
  cardApplied: boolean;
  applicationStatus?: ApplicationStatusName;
  cardName?: string;
  interestDifference?: number;
  onDebtConsolidationWaitlist?: boolean;
}

export const useActionItems = () => {
  const { totalBalance, eligible } = useSelector(getStudentLoanData);
  const { waitListPosition, waitListLength } = useSelector(getCardData);
  const { application } = useSelector(getApplicationData);

  const { cardApplied, status, studentLoanAssistanceAppointment, onDebtConsolidationWaitlist } = application ?? {};
  const hardOffer = application?.hardOffer as HardOfferData | undefined;

  const debtSummaryData = application?.debtSummary as DebtSummary | undefined;
  const creditCardDebt = debtSummaryData?.[LoanType.CreditCard];

  const primaryCard = getPrimaryCardData(creditCardDebt);

  return actionItems({
    savings: hardOffer?.offerSummary.moneySaved,
    monthsSaved: hardOffer?.offerSummary.monthsSaved,
    studentLoanEligible: eligible === true,
    studentLoansBalance: totalBalance,
    studentLoanAssistanceAppointment,
    waitlistPosition: waitListPosition,
    waitlistLength: waitListLength,
    cardCashbackPercentage: 5,
    cardApplied: cardApplied ?? false,
    applicationStatus: status,
    cardName: primaryCard?.cardName,
    interestDifference: primaryCard?.interestDifference,
    onDebtConsolidationWaitlist: onDebtConsolidationWaitlist ?? false,
  });
};

const timeSavedLabel = (prefix: string, monthsSaved?: number) => {
  if (!monthsSaved || monthsSaved <= 0) {
    return null;
  }

  const timeText =
    monthsSaved >= 36
      ? `${Math.floor(monthsSaved / 12)} years earlier`
      : `${monthsSaved} month${monthsSaved > 1 && 's'} earlier`;

  return (
    <>
      {' '}
      {prefix}
      <strong> {timeText}</strong>
    </>
  );
};

export const actionItems = ({
  savings,
  monthsSaved,
  studentLoanEligible,
  studentLoansBalance,
  studentLoanAssistanceAppointment,
  waitlistPosition,
  waitlistLength,
  cardCashbackPercentage,
  cardApplied,
  applicationStatus,
  cardName,
  interestDifference,
  onDebtConsolidationWaitlist,
}: ItemsData) => {
  const items = [
    {
      id: 'signLoanAgreement',
      name: 'Sign Loan Agreement',
      description: (
        <span>
          You're almost at the finish line for saving <strong>{formatMonetaryAmount(savings)}</strong>
          {timeSavedLabel('and getting out of debt', monthsSaved)}!
        </span>
      ),
      icon: SignLoanAgreementIcon,
      route: RoutePath.DocuSignSignature,
      visible: applicationStatus === ApplicationStatusName.SigningAgreement,
    },
    {
      id: 'setUpRepayment',
      name: 'Set Up Repayment',
      icon: SetupRepaymentIcon,
      description: (
        <span>
          You're close to saving <strong>{formatMonetaryAmount(savings)}</strong>
          {timeSavedLabel('and getting out of debt', monthsSaved)}. The next step is to set up your repayment method
        </span>
      ),
      route: RoutePath.RepaymentMethod,
      visible: applicationStatus === ApplicationStatusName.SetUpDeduction,
    },
    {
      id: 'verifyEmployment',
      name: 'Verify Employment',
      icon: VerifyEmploymentIcon,
      description: (
        <span>
          We need to verify some information so you can save <strong>{formatMonetaryAmount(savings)}</strong>
          {timeSavedLabel('and get out of debt', monthsSaved)}.
        </span>
      ),
      route: RoutePath.SetupAccount,
      visible: applicationStatus === ApplicationStatusName.Verification,
    },
    {
      id: 'debtConsolidation',
      name: 'Reduce High Debt Interest',
      description: (
        <span>
          Plannery can save you <strong>{formatMonetaryAmount(savings)}</strong>
          {timeSavedLabel('and get you out of debt', monthsSaved)}!
        </span>
      ),
      icon: DebtConsolidationIcon,
      route: RoutePath.DebtConsolidationSummary,
      visible: applicationStatus === ApplicationStatusName.OfferAvailable && !onDebtConsolidationWaitlist,
    },
    {
      id: 'studentLoanForgiveness',
      name: 'Get Student Loans Forgiven',
      description: (
        <span>
          Your <strong>{formatMonetaryAmount(studentLoansBalance)}</strong> in federal student loans likely qualifies
          for assistance.
        </span>
      ),
      icon: StudentLoanIcon,
      route: RoutePath.StudentLoanEmployerType,
      visible: studentLoanEligible && !studentLoanAssistanceAppointment,
    },
    {
      id: 'referralProgram',
      name: 'Spread The Word',
      description: (
        <>
          <span style={{ marginBottom: '12px', display: 'block' }}>
            You're #<strong>{waitlistPosition}</strong> on the waitlist for Plannery Card. Spread the word and{' '}
            <strong>help us launch!</strong>
          </span>
          {waitlistLength && (
            <div style={{ marginBottom: '-8px' }}>
              <RewardProgress
                title="Launch Progress"
                totalCount={10_000}
                currentCount={waitlistLength}
                originalPosition={0}
                showPositionLabel={false}
                showCount
              />
            </div>
          )}
        </>
      ),
      icon: ReferralProgramIcon,
      route: RoutePath.ReferralProgram,
      visible: cardApplied,
    },
    {
      id: 'planneryCard',
      name: 'Get the Plannery Card',
      description: (
        <span>
          Exceptional care deserves exceptional perks: Earn up to <strong>{cardCashbackPercentage}% back</strong> on
          your purhcases and
          {interestDifference ? (
            <>
              {' '}
              pay <strong>{interestDifference}% less interest</strong> than your {cardName}.
            </>
          ) : (
            ` get interest rates up to 50% below market.`
          )}
        </span>
      ),
      icon: PlanneryCardIcon,
      route: RoutePath.CardInfo,
      visible: !cardApplied,
    },
  ];

  return {
    actionItems: items,
    nextRoute: getFirstVisibleAction(items)?.route,
  };
};

const getFirstVisibleAction = (items: ActionItem[]) => items.find((item) => item.visible);

const getPrimaryCardData = (creditCardDebt?: Debt) => {
  const { cardOffer, tradelines } = creditCardDebt ?? {};

  if (!creditCardDebt || !cardOffer) {
    return;
  }

  const { primaryCardId, totalInterest: cardOfferTotalInterest } = cardOffer;

  const tradeline = tradelines?.find(({ id }) => id === primaryCardId);
  if (!tradeline) {
    return;
  }

  const { firm, totalInterest } = tradeline;
  if (!totalInterest) {
    return;
  }

  const interestDifference = Math.floor(((totalInterest - cardOfferTotalInterest) / totalInterest) * 100);

  const cardName = `${firm}${firm.toLowerCase().includes('card') ? '' : ' Card'}`;

  return {
    cardName,
    interestDifference,
  };
};
