import React, { useState } from 'react';
import FormNavigation from 'components/FormNavigation';
import RadioButtonList from 'components/Common/RadioButtonList/RadioButtonList';
import { FlowComponentType } from 'routes/FlowRouter';
import Button from 'components/Button';
import { StudentLoanNotEnrolledResult as PslfNotEnrolledResult } from 'enums/StudentLoanForgivenessFlowResults';

import QuestionsWrapper from './Wrapper/QuestionsWrapper';

import styles from './Questions.module.scss';

const options = [
  { id: PslfNotEnrolledResult.Applying, label: 'I’m currently applying or already applied' },
  { id: PslfNotEnrolledResult.WontApprove, label: "I don't think I'll be approved" },
  { id: PslfNotEnrolledResult.DifficultToApply, label: "It's too difficult to apply" },
  { id: PslfNotEnrolledResult.WontSaveMoney, label: "I don't think it will save me money" },
];

const PslfNotEnrolled = ({ navigationInfo, handleNext: onNext }: FlowComponentType) => {
  const [selected, setSelected] = useState<PslfNotEnrolledResult | null>(null);

  const handleNext = () => {
    analytics.track('Student Loan Not Enrolled Selected', { selected });
    onNext(selected!);
  };
  return (
    <>
      <FormNavigation {...navigationInfo} />
      <QuestionsWrapper>
        <>
          <RadioButtonList
            label="It doesn't look like you're enrolled in this program. Why not?"
            selected={selected}
            setSelected={setSelected}
            options={options}
          />

          <Button disabled={Boolean(!selected)} onClick={handleNext} className={styles.button}>
            Next
          </Button>
        </>
      </QuestionsWrapper>
    </>
  );
};

export default PslfNotEnrolled;
