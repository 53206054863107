import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { ReactComponent as PlanneryLogo } from 'images/plannery-logo.svg';
import { getApplicationData } from 'selectors/getApplicationData';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';

import styles from './Header.module.scss';

interface HeaderProps {
  totalDebt?: number;
  hideTotalDebt?: boolean;
}

const Header = ({ totalDebt, hideTotalDebt = false }: HeaderProps) => {
  const { application } = useSelector(getApplicationData);

  const { borrowerFirstName: firstName } = application!;

  return (
    <div
      className={clsx(styles.header, {
        [styles.aligned]: hideTotalDebt,
      })}
    >
      <PlanneryLogo className={styles.logo} />

      {!hideTotalDebt && (
        <div className={styles.headerLabel}>
          <p>{firstName}, your Total Debt is:</p>
          <p className={styles.debtValue}>{formatMonetaryAmount(totalDebt)}</p>
        </div>
      )}
    </div>
  );
};

export default Header;
