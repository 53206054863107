import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useQueryParam from 'hooks/useQueryParam';
import { UrlParams } from 'enums/UrlParams';
import Button from 'components/Button';
import { ReactComponent as LoadingSpinner } from 'images/loader.svg';
import { ReactComponent as RoadSign } from 'images/road-sign.svg';
import { ReactComponent as CheckIcon } from 'images/check-icon-rounded.svg';
import { FlowComponentType } from 'routes/FlowRouter';
import { PaymentStatusEnum } from 'api/PaymentApi';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getStudentLoanApplicationPaymentStatus } from 'thunks';
import StateContainer from 'components/StateContainer';
import { StudentLoanPayingResult } from 'enums/StudentLoanForgivenessFlowResults';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';

import styles from './PaymentResult.module.scss';

enum StripeResult {
  Success = 'success',
  Cancel = 'cancel',
}

const PaymentResult = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { applicationId } = useSelector(getStudentLoanData);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatusEnum>();
  const [intervalValue, setIntervalValue] = useState<NodeJS.Timeout | null>(null);
  const result = useQueryParam(UrlParams.StripeResult);

  const pollPaymentStatus = async () => {
    const paymentResult = await dispatchWithUnwrap(getStudentLoanApplicationPaymentStatus(applicationId!));
    setPaymentStatus(paymentResult.status);
    if (paymentResult.status === PaymentStatusEnum.Paid || paymentResult.status === PaymentStatusEnum.Failed) {
      clearInterval(intervalValue!);
    }
  };

  const handlePaid = () => {
    handleNext(StudentLoanPayingResult.Success);
  };

  const handleFailed = () => {
    handleNext(StudentLoanPayingResult.Failure);
  };

  useEffect(() => {
    if (result === StripeResult.Cancel) {
      setPaymentStatus(PaymentStatusEnum.Failed);
      return;
    }

    const interval = setInterval(() => {
      pollPaymentStatus();
    }, 5000);

    setIntervalValue(interval);

    return () => {
      clearInterval(interval);
    };
  }, []);

  switch (paymentStatus) {
    case PaymentStatusEnum.Pending:
      return (
        <div className={styles.container}>
          <StateContainer icon={<LoadingSpinner />} title="Payment in progress" />
        </div>
      );
    case PaymentStatusEnum.Paid:
      return (
        <>
          <FormNavigation {...navigationInfo} showBackLink={false} />
          <FormContainer
            icon={<CheckIcon />}
            title="Payment Successful"
            subtitle="Let's finish the rest of your application."
          >
            <Button className={styles.button} onClick={handlePaid}>
              Continue
            </Button>
          </FormContainer>
        </>
      );
    case PaymentStatusEnum.Failed:
      return (
        <>
          <FormNavigation {...navigationInfo} />
          <FormContainer
            icon={<RoadSign />}
            title="Payment Failed"
            subtitle="Please try again or contact us if you still have issues."
          >
            <Button className={styles.button} onClick={handleFailed}>
              Try Again
            </Button>
          </FormContainer>
        </>
      );
    default:
      return (
        <div className={styles.container}>
          <StateContainer icon={<LoadingSpinner />} title="Checking payment status" />
        </div>
      );
  }
};

export default PaymentResult;
