import React, { useState } from 'react';
import FormNavigation from 'components/FormNavigation';
import Checkbox from 'components/Checkbox/Checkbox';
import { FlowComponentType } from 'routes/FlowRouter';
import Button from 'components/Button';
import { StudentLoanPslfWontSaveMoneyResult as StudentLoanPslfSituationsResult } from 'enums/StudentLoanForgivenessFlowResults';

import QuestionsWrapper from './Wrapper/QuestionsWrapper';

import styles from './Questions.module.scss';

const PslfSituations = ({ navigationInfo, handleNext: onNext }: FlowComponentType) => {
  const [isStudent, setIsStudent] = useState<boolean>(false);
  const [isLeavingNonProfits] = useState<boolean>(false);

  const handleNext = () => {
    analytics.track(`Student Loan PSLF Situations Details`, { isStudent, isLeavingNonProfits });
    onNext(isStudent ? StudentLoanPslfSituationsResult.IsStudent : StudentLoanPslfSituationsResult.Other);
  };
  return (
    <>
      <FormNavigation {...navigationInfo} />
      <QuestionsWrapper>
        <>
          <div className={styles.checkboxList}>
            <p className={styles.label}>Do any of these situations apply to you?</p>
            <Checkbox
              label="I’m currently or was recently a student and none of my loans are due yet."
              checked={isStudent}
              onChange={(e) => {
                setIsStudent(e.target.checked);
              }}
            />
          </div>
          <Button onClick={handleNext} className={styles.button}>
            Next
          </Button>
        </>
      </QuestionsWrapper>
    </>
  );
};

export default PslfSituations;
