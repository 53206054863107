import { PaymentApi, SetupStudentLoanPaymentData } from 'api/PaymentApi';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const createSetupStudentLoanPayment = (api: PaymentApi) =>
  createAsyncThunk('payment/studentLoanAssitance/setup', async (data: SetupStudentLoanPaymentData, thunkApi) => {
    try {
      return await api.setupStudentLoanPayment(data);
    } catch (error) {
      thunkApi.rejectWithValue(error.response.data);

      throw error;
    }
  });
