import React from 'react';

import Button from 'components/Button';
import { ReactComponent as CheckIcon } from 'images/check-icon-rounded.svg';
import { FlowComponentType } from 'routes/FlowRouter';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';

const ApplyStepsCompleted = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => (
  <>
    <FormNavigation {...navigationInfo} showBackLink={false} />
    <FormContainer
      icon={<CheckIcon />}
      title="Application Submitted"
      subtitle="Thank you. We'll contact you with updates on your application."
    >
      <Button onClick={handleNext}>Done</Button>
    </FormContainer>
  </>
);

export default ApplyStepsCompleted;
