import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaycheckSwitchApi } from 'api/PaycheckSwitchApi';

export const createGetPaycheckSwitchOfferThunk = (api: PaycheckSwitchApi) =>
  createAsyncThunk('paycheckSwitch/offer', async (applicationId: string, thunkApi) => {
    try {
      return await api.getPaycheckSwitchOffer(applicationId);
    } catch (error: any) {
      thunkApi.rejectWithValue(error);

      throw error;
    }
  });
