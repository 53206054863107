import { createAsyncThunk } from '@reduxjs/toolkit';
import EmployersApi from 'api/EmployersApi';

export const createGetEmployerData = (api: EmployersApi) =>
  createAsyncThunk('employers/getLogo', async (data: { payrollProvider: string; employerId: string }, thunkApi) => {
    try {
      const { payrollProvider, employerId } = data;
      return await api.getEmployerData(payrollProvider, employerId);
    } catch (error: any) {
      thunkApi.rejectWithValue(error.response.data);
      throw error;
    }
  });
