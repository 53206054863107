import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationDataApi } from 'api/ApplicationDataApi';

export const createJoinDebtConsolidationWaitlistThunk = (api: ApplicationDataApi) =>
  createAsyncThunk('applicationData/joinDebtConsolidationWaitlist', async (applicationId: string, thunkApi) => {
    try {
      await api.joinDebtConsolidationWaitlist(applicationId);
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  });

export default createJoinDebtConsolidationWaitlistThunk;
