import React, { useState } from 'react';
import FormNavigation from 'components/FormNavigation';
import RadioButtonList from 'components/Common/RadioButtonList/RadioButtonList';
import { FlowComponentType } from 'routes/FlowRouter';
import Button from 'components/Button';
import { StudentLoanWhyNotEnrolledResult as SaveNotEnrolledResult } from 'enums/StudentLoanForgivenessFlowResults';

import QuestionsWrapper from './Wrapper/QuestionsWrapper';

import styles from './Questions.module.scss';

const options = [
  { id: SaveNotEnrolledResult.WontApprove, label: "I don't think I'll be approved" },
  { id: SaveNotEnrolledResult.DifficultToApply, label: "It's too difficult to apply" },
  { id: SaveNotEnrolledResult.WontSaveMoney, label: "I don't think it will save me money" },
];

const SaveNotEnrolled = ({ navigationInfo, handleNext: onNext }: FlowComponentType) => {
  const [selected, setSelected] = useState<SaveNotEnrolledResult | null>(null);

  const handleNext = () => {
    analytics.track('SAVE Plan Not Enrolled Selected', { selected });
    onNext();
  };
  return (
    <>
      <FormNavigation {...navigationInfo} />
      <QuestionsWrapper>
        <>
          <RadioButtonList
            label="Why are you not enrolled in this?"
            selected={selected}
            setSelected={setSelected}
            options={options}
          />

          <Button disabled={Boolean(!selected)} onClick={handleNext} className={styles.button}>
            Next
          </Button>
        </>
      </QuestionsWrapper>
    </>
  );
};

export default SaveNotEnrolled;
