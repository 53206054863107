import { PaymentApi } from 'api/PaymentApi';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const createGetStudentLoanApplicationPaymentStatusThunk = (api: PaymentApi) =>
  createAsyncThunk('payment/studentLoanAssitance/status', async (applicationId: string, thunkApi) => {
    try {
      return await api.getStudentLoanAssistancePaymentStatus(applicationId);
    } catch (error) {
      thunkApi.rejectWithValue(error.response.data);

      throw error;
    }
  });
