export enum UrlParams {
  FBC = 'fb',
  FBP = 'fbp',
  Flow = 'flow',
  AnonymousId = 'anonymous_id',
  FullName = 'fullName',
  Credentials = 'credentials',
  CardColor = 'color',
  CardVersion = 'cardVersion',
  ProfessionGroup = 'professionGroup',
  ResumeProcess = 'resume-process',
  Partner = 'partner',
  Goal = 'goal',
  UtmSource = 'utm_source',
  UtmMedium = 'utm_medium',
  UtmCampaign = 'utm_campaign',
  UtmContent = 'utm_content',
  InitialReferrer = 'initialReferrer',
  ApiRevision = 'apiRevision',
  StripeResult = 'result',
}
