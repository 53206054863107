import React, { ChangeEvent, FC, useEffect } from 'react';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useScript } from 'components/ScriptsLoader/ScriptsLoader';
import { YourDetailsVariable } from 'enums/LoanFormVariables';
import { getParsedAddress } from 'utils/getParsedAddress';
import { useForm } from 'react-hook-form';
import { getMessageForRequiredFields } from 'utils/errors';
import { STATE_OPTIONS } from 'utils/getCountryStateLabel';

import Input from 'components/Input';
import NumberInput from 'components/NumberInput';
import InputSelect from 'components/InputSelect';

import styles from './AddressFields.module.scss';

enum YourDetailsInputLabel {
  AddressLine1 = 'Address Line 1',
  AddressLine2 = 'Address Line 2 (Optional)',
  City = 'City',
  ZipCode = 'Zip Code',
  State = 'State',
}

export interface Address {
  line1?: string;
  line2?: string;
  city?: string;
  zip?: string;
  state?: string;
}

export interface AddressProps {
  value?: Address;
  onChange?: (value: Address, isValid: boolean) => void;
  disabled?: boolean;
  hideLine2?: boolean;
}

interface GeoCompletionResult {
  description: string;
}

const AddressFields: FC<AddressProps> = ({ value, onChange: onChangeArg, disabled, hideLine2 }) => {
  const googleMapScript = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
  );

  const {
    init,
    ready,
    suggestions: { status, data },
    setValue: setAutocompleteValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    initOnMount: false,
    requestOptions: {
      componentRestrictions: { country: 'us' },
      types: ['address'],
    },
    debounce: 300,
  });

  useEffect(() => {
    if (googleMapScript.loaded) {
      init();
    }
  }, [googleMapScript.loaded]);

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleGooglePlacesInput = async (event: ChangeEvent<HTMLInputElement>) => {
    setValue(YourDetailsVariable.AddressLine1, event.target.value);
    setAutocompleteValue(event.target.value);
    trigger(event.target.name as YourDetailsVariable);

    callOnChange();
  };

  const handleGooglePlacesSelect = (event: GeoCompletionResult) => async () => {
    const { description } = event;

    const [geocoderResult] = await getGeocode({ address: description });
    const address = getParsedAddress(geocoderResult.address_components);

    setValue(YourDetailsVariable.AddressLine1, address.address);
    setValue(YourDetailsVariable.ZipCode, address.zip);
    setValue(YourDetailsVariable.City, address.city);
    setValue(YourDetailsVariable.ArgyleState, address.state);

    trigger(YourDetailsVariable.AddressLine1 as YourDetailsVariable);
    trigger(YourDetailsVariable.ZipCode as YourDetailsVariable);
    trigger(YourDetailsVariable.City as YourDetailsVariable);
    trigger(YourDetailsVariable.ArgyleState as YourDetailsVariable);

    clearSuggestions();

    callOnChange();
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id: placeId,
        structured_formatting: { main_text: mainText, secondary_text: secondaryText },
      } = suggestion;

      return (
        <li key={placeId} onClick={handleGooglePlacesSelect(suggestion)} className={styles.dropdownOption}>
          {mainText} {secondaryText}
        </li>
      );
    });

  const defaultValues = {
    [YourDetailsVariable.AddressLine1]: value?.line1,
    [YourDetailsVariable.AddressLine2]: value?.line2,
    [YourDetailsVariable.City]: value?.city,
    [YourDetailsVariable.ZipCode]: value?.zip,
    [YourDetailsVariable.ArgyleState]: value?.state,
  };

  const {
    register,
    watch,
    formState: { errors },
    trigger,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const watcher = watch();

  useEffect(() => {
    register(YourDetailsVariable.AddressLine1, {
      required: getMessageForRequiredFields(YourDetailsInputLabel.AddressLine1),
    });
    register(YourDetailsVariable.AddressLine2, {});
    register(YourDetailsVariable.City, {
      required: getMessageForRequiredFields(YourDetailsInputLabel.City),
    });
    register(YourDetailsVariable.ZipCode, {
      required: getMessageForRequiredFields(YourDetailsInputLabel.ZipCode),
    });
    register(YourDetailsVariable.ArgyleState, {
      required: getMessageForRequiredFields(YourDetailsInputLabel.State),
    });
  }, [register, watcher]);

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as YourDetailsVariable, event.target.value.trim());
    trigger(event.target.name as YourDetailsVariable);
    callOnChange();
  };

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as YourDetailsVariable, event.target.value);
    trigger(event.target.name as YourDetailsVariable);
    callOnChange();
  };

  const callOnChange = () => {
    onChangeArg?.(
      {
        line1: watcher[YourDetailsVariable.AddressLine1],
        line2: watcher[YourDetailsVariable.AddressLine2],
        city: watcher[YourDetailsVariable.City],
        state: watcher[YourDetailsVariable.ArgyleState],
        zip: watcher[YourDetailsVariable.ZipCode],
      },
      Object.keys(errors).length === 0,
    );
  };

  return (
    <>
      <div className={styles.dropdownContainer} ref={ref}>
        <Input
          label={YourDetailsInputLabel.AddressLine1}
          placeholder="Address Line 1"
          errorMessage={errors[YourDetailsVariable.AddressLine1]?.message}
          name={YourDetailsVariable.AddressLine1}
          onBlur={onBlur}
          value={watcher[YourDetailsVariable.AddressLine1]}
          onChange={handleGooglePlacesInput}
          disabled={(!googleMapScript.loaded && !ready) || disabled}
        />
        {status === 'OK' && <ul className={styles.dropdownOptionsList}>{renderSuggestions()}</ul>}
      </div>

      {hideLine2 !== true && (
        <Input
          label={YourDetailsInputLabel.AddressLine2}
          placeholder="Address Line 2"
          name={YourDetailsVariable.AddressLine2}
          onBlur={onBlur}
          onChange={onChange}
          value={watcher[YourDetailsVariable.AddressLine2]}
          disabled={(!googleMapScript.loaded && !ready) || disabled}
        />
      )}

      <Input
        label={YourDetailsInputLabel.City}
        placeholder="City"
        errorMessage={errors[YourDetailsVariable.City]?.message}
        name={YourDetailsVariable.City}
        onBlur={onBlur}
        onChange={onChange}
        value={watcher[YourDetailsVariable.City]}
        disabled={disabled}
      />

      <NumberInput
        label={YourDetailsInputLabel.ZipCode}
        placeholder="Zip code"
        errorMessage={errors[YourDetailsVariable.ZipCode]?.message}
        name={YourDetailsVariable.ZipCode}
        onChange={onChange}
        value={watcher[YourDetailsVariable.ZipCode]}
        disabled={disabled}
      />

      <InputSelect
        label={YourDetailsInputLabel.State}
        options={STATE_OPTIONS}
        onChange={async (option) => {
          setValue(YourDetailsVariable.ArgyleState, option.value);
          trigger(YourDetailsVariable.ArgyleState as YourDetailsVariable);
          // TODO: Figure out why the watcher doesn't update without this.
          watcher[YourDetailsVariable.ArgyleState] = option.value;
          callOnChange();
        }}
        value={watcher[YourDetailsVariable.ArgyleState]}
        placeholder="State"
        disabled={disabled}
        name={YourDetailsVariable.ArgyleState}
      />
    </>
  );
};

export default AddressFields;
