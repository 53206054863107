import React, { useState } from 'react';

import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import OtpInput from 'components/OtpInput/OtpInput';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { authSessionThunk, authSmsCode } from 'thunks';
import { useSelector } from 'react-redux';
import { getAuthData } from 'selectors/getAuthData';
import { getYourContactData } from 'selectors/getYourContact';
import { getYourNameData } from 'selectors/yourName';

import styles from './VerifyPhoneNumber.module.scss';

const VerifyPhoneNumber = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const [code, setCode] = useState<string>('');
  const [codeIsValid, setCodeIsValid] = useState<boolean>(false);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const { phoneId } = useSelector(getAuthData);
  const { phone_number: phoneNumber } = useSelector(getYourContactData);
  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);

  const onChange = (value: string) => {
    const isValid = value.trim().length === 6;

    setCodeIsValid(isValid);
    setCode(value);
  };

  const handleContinue = async () => {
    if (isAuthenticating) {
      return;
    }
    setIsAuthenticating(true);
    const authResponse = await dispatchWithUnwrap(authSmsCode({ code, phoneId: phoneId! }));
    if (authResponse.verified) {
      // this is needed for a future step, will wait for this to resolve when it's needed
      dispatchWithUnwrap(
        authSessionThunk({
          phoneNumber,
          firstName,
          lastName,
          sessionToken: authResponse.sessionToken,
        }),
      );
      handleNext();
    }
    setIsAuthenticating(false);
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="Verify Your Identity" subtitle="Enter the 6-digit authorization code sent to your phone.">
        <OtpInput
          name="code"
          className={styles.formInput}
          onChange={onChange}
          value={code}
          valueLength={6}
          disabled={isAuthenticating}
          autoFocus
        />

        <p className={styles.consent}>
          By clicking Agree and Continue, I hereby consent to the{' '}
          <a href="https://www.planneryapp.com/esign-agreement" target="_blank" rel="noreferrer">
            E-Sign Agreement
          </a>
          ,{' '}
          <a href="https://www.planneryapp.com/terms-of-service" target="_blank" rel="noreferrer">
            Terms of Service
          </a>
          , and{' '}
          <a href="https://www.planneryapp.com/communication-policy" target="_blank" rel="noreferrer">
            Communication Policy
          </a>{' '}
          and I am providing written consent under the FCRA for Plannery to obtain consumer report information from my
          credit profile.
        </p>

        <Button className={styles.button} disabled={!codeIsValid} isLoading={isAuthenticating} onClick={handleContinue}>
          Agree and Continue
        </Button>
      </FormContainer>
    </>
  );
};

export default VerifyPhoneNumber;
