import React from 'react';

import { ReactComponent as RoadSign } from 'images/road-sign.svg';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';

import styles from './ComeBackWhenDue.module.scss';

const ComeBackWhenDue = ({ handleNext, navigationInfo }: FlowComponentType) => (
  <div>
    <FormNavigation {...navigationInfo} />
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <RoadSign />
      </div>
      <div className={styles.title}>You're all set! Come back when your loans are due. </div>
      <div className={styles.description}>Learn other ways Plannery can help you achieve your financial goals!</div>
      <Button type={ButtonType.Primary} onClick={handleNext}>
        Continue
      </Button>
    </div>
  </div>
);

export default ComeBackWhenDue;
