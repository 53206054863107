import Api, { FetchMethodType } from './Api';

export interface MakeACHPaymentResponse {
  success: boolean;
}

export interface MakeACHPaymentData {
  applicationId: string;
}

export interface SetupStudentLoanPaymentData {
  applicationId: string;
  returnUrl: string;
}

export interface SetupStudentLoanPaymentResponse {
  checkoutUrl: string;
}

export enum PaymentStatusEnum {
  Paid = 'paid',
  Pending = 'pending',
  Failed = 'failed',
}

export interface StudentLoanPaymentStatusResponse {
  status: PaymentStatusEnum;
}

export interface PaymentApi {
  makeACHPayment(data: MakeACHPaymentData): Promise<MakeACHPaymentResponse>;
  setupStudentLoanPayment(data: SetupStudentLoanPaymentData): Promise<SetupStudentLoanPaymentResponse>;
  getStudentLoanAssistancePaymentStatus(applicationId: string): Promise<StudentLoanPaymentStatusResponse>;
}

export default class ACHPaymentApi extends Api implements PaymentApi {
  async makeACHPayment(data: MakeACHPaymentData): Promise<MakeACHPaymentResponse> {
    return this.fetch<MakeACHPaymentResponse>('/ach/makePayment', {
      method: FetchMethodType.POST,
      body: data,
    });
  }

  async setupStudentLoanPayment(data: SetupStudentLoanPaymentData): Promise<SetupStudentLoanPaymentResponse> {
    return this.fetch<SetupStudentLoanPaymentResponse>(`/payment/studentLoanAssitance/setup/${data.applicationId}`, {
      method: FetchMethodType.POST,
      body: {
        returnUrl: data.returnUrl,
      },
    });
  }

  async getStudentLoanAssistancePaymentStatus(applicationId: string): Promise<StudentLoanPaymentStatusResponse> {
    return this.fetch<StudentLoanPaymentStatusResponse>(`/payment/studentLoanAssitance/status/${applicationId}`, {
      method: FetchMethodType.GET,
    });
  }
}
