import React from 'react';
import { ReactComponent as ContractIcon } from 'images/contract-review-icon.svg';

import styles from './ReviewCard.module.scss';

interface ReviewCardProps {
  title: string;
  content: string | JSX.Element;
}

const ReviewCard = ({ title, content }: ReviewCardProps) => (
  <div className={styles.reviewContainer}>
    <div className={styles.reviewTitle}>{title}</div>
    <div className={styles.content}>
      <ContractIcon className={styles.reviewIcon} />
      <div className={styles.reviewText}>{content}</div>
    </div>
  </div>
);

export default ReviewCard;
